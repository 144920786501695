<template>
  <div>
    <v-card>
      <v-card-title>
      <v-icon color="primary">
        mdi-clock-outline
      </v-icon>
        <span class="pl-2">Pagesat e ardhshme</span>
      </v-card-title>
      <v-data-table
        item-key="id"
        :loading="isLoading"
        loading-text="Po ngarkohet... Ju lutemi prisni"
        :headers="headers"
        :items="payments"
        :search="search"
      >
        <template v-slot:top>
          <v-text-field
            v-model="search"
            label="Kërko"
            class="mx-4"
          ></v-text-field>
        </template>

        <template v-slot:item.redemption_preference="{ item }">
          <v-chip
            :color="getRedemptionPreferenceColor(item.redemption_preference)"
            dark
          >
            {{ getRedemptionPreferenceValue(item.redemption_preference) }}
            <v-icon class="ml-2">
              {{ getIconOfRedemptionPreference(item.redemption_preference) }}
            </v-icon>
          </v-chip>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="viewUpcomingPayment(item)">
            mdi-eye
          </v-icon>

          <v-btn depressed small density="compact" color="primary" @click="redeemPayment(payments.indexOf(item))">
            <v-icon dark> mdi-bank-plus </v-icon>
          </v-btn>

        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      payments: [],
      isLoading: false,
      search: "",
      headers: [
        { text: "ID Përdoruesit", value: "id", align: "start", filterable: false },
        { text: "Emri", value: "user_name" },
        { text: "Shlyerjet", value: "redemption_preference" },
        { text: "Rezervimet", value: "total_reservations" },
        { text: "Shuma", value: "total_amount" },
        { text: "Veprimet", value: "actions", sortable: false },
      ],
    };
  },
  mounted() {
    this.loadPayments();
  },
  methods: {
    getIconOfRedemptionPreference(redemption_preference)
    {
      if(redemption_preference==2)
      {
        return "mdi-bank";
      }
      else if(redemption_preference==3)
      {
        return "mdi-credit-card";
      }
      else if(redemption_preference==4)
      {
        return "mdi-credit-card-multiple";
      }
      else
      {
        return "mdi-cash";
      }
    },
    getRedemptionPreferenceColor(redemption_preference)
    {
      if(redemption_preference==2)
      {
        return "primary";
      }
      else if(redemption_preference==3)
      {
        return "info";
      }
      else if(redemption_preference==4)
      {
        return "secondary";
      }
      else
      {
        return "success";
      }
    },
    getRedemptionPreferenceValue(redemption_preference)
    {
      if(redemption_preference==2)
      {
        return "Bank";
      }
      else if(redemption_preference==3)
      {
        return "PayPal";
      }
      else if(redemption_preference==4)
      {
        return "Para me Mob App";
      }
      else
      {
        return "Kesh";
      }
    },
    viewRoute(route) {
      this.$router.push({
        name: "view-route",
        params: { route_id: route.id},
      });
    },
    loadPayments() {
      this.isLoading = true;
      this.payments = [];
      axios
        .get(`/users/upcoming-payments`)
        .then((response) => {
          this.payments = response.data;
        })
        .catch((error) => {
          this.$notify({
            title: "Gabim",
            text: "Gabim gjatë marrjes së pagesave",
            type: "error",
          });
          console.log(error);
          this.$swal("Gabim", error.response.data.message, "error");
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    viewUpcomingPayment(payment)
    {
      this.$router.push({
        name: "view-upcoming-payment",
        params: {
          user_id: payment.id,
        },
      });
    },
    redeemPayment(index) {
      this.$swal({
        title: "Shlyej pagesën",
        html: "Jeni të sigurt që dëshironi të shlyeni" + this.payments[index].total_amount + " për " + this.payments[index].user_name + "? <br/><br/>" + 
        this.showRedemptionDetails(index),
        icon: "warning",
        footer: this.showRedemptionFooter(index),
        showCancelButton: true,
        confirmButtonText: "Po, shlyeje",
      }).then((result) => {
        if (result.isConfirmed) {
          this.redeemPaymentServer(this.payments[index].id, index);
        }
      });
    },

    showRedemptionFooter(index)
    {
      let redemption_preference = this.payments[index].redemption_preference;
      if(redemption_preference==2)
      {
        return '<h2>Llogari Bankare</h2>';
      }
      else if(redemption_preference==3)
      {
        return '<h2>PayPal</h2>';
      }
      else if(redemption_preference==4)
      {
        return '<h2>Para me Mob App</h2>';
      }
      else
      {
        return '<h2>Kesh</h2>';
      }
    },
    showRedemptionDetails(index)
    {
      let redemption_preference = this.payments[index].redemption_preference;
      console.log(redemption_preference);
      if(redemption_preference==2)
      {
        return '<ul><li><b>Emri Bankës:</b> ' + this.payments[index].redemption_details.bank_name + '</li>' +
        '<li><b>Numri i llogarisë:</b> ' + this.payments[index].redemption_details.account_number + '</li>' +
        '<li><b>Emri i përfituesit:</b> ' + this.payments[index].redemption_details.beneficiary_name + '</li>' +
        '<li><b>Adresa e përfituesit:</b> ' + this.payments[index].redemption_details.beneficiary_address + '</li>' +
        (this.payments[index].redemption_details.iban != null ? '<li><b>IBAN:</b> ' + this.payments[index].redemption_details.iban + '</li>' : '') +
        (this.payments[index].redemption_details.swift != null ? '<li><b>Swift:</b> ' + this.payments[index].redemption_details.swift + '</li>' : '') +
        (this.payments[index].redemption_details.routing_number != null ? '<li><b>Numri Routing:</b> ' + this.payments[index].redemption_details.routing_number + '</li>' : '') +
        (this.payments[index].redemption_details.bic != null ? '<li><b>Kodi i Identifikimit të Bankës:</b> ' + this.payments[index].redemption_details.bic + '</li>' : '') + 
        '</ul>';
      }
      else if(redemption_preference==3)
      {
        return "<b>PayPal:</b> " + this.payments[index].redemption_details.email;
      }
      else if(redemption_preference==4)
      {
        return '<ul><li><b>Numri telefonit:</b> ' + this.payments[index].redemption_details.phone_number + '</li>' +
        '<li><b>Network:</b> ' + this.payments[index].redemption_details.network + '</li>' +
        '<li><b>Emri:</b> ' + this.payments[index].redemption_details.name + '</li>' +
        '</ul>';
      }
      else
      {
        return "";
      }
    },

    redeemPaymentServer(user_id, index) {
      this.isLoading = true;
      axios
        .post(`/users/redeem`, {
          user_id: user_id,
        })
        .then((response) => {
          this.$notify({
            title: "Sukses",
            text: "Pagesa u shlye",
            type: "success",
          });
          this.payments.splice(index, 1);
        })
        .catch((error) => {
          this.$notify({
            title: "Gabim",
            text: "Gabim gjatë shlyerjes së pagesës",
            type: "error",
          });
          console.log(error);
          this.$swal("Gabim", error.response.data.message, "error");
        })
        .then(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):hover{
  cursor: pointer;
  background: rgba($primary-shade--light, 0.15) !important;
}
</style>